.radar {
    background-color : var(--primaria-brightest);
    border-radius    : 50%;
    width            : 100%;
    opacity          : .8;
    height           : 100%;
    position         : absolute;
    left             : 50%;
    top              : 50%;
    transform        : translate(-50%, -50%);
    -moz-transform   : translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.radarLine1 {
    position     : absolute;
    left         : 50%;
    top          : 50%;
    transform    : translate(-50%, -50%);
    border       : 1px dashed #00000077;
    border-radius: 50%;
    height       : 20%;
    width        : 20%;
}

.radarLine2 {
    position     : absolute;
    left         : 50%;
    top          : 50%;
    transform    : translate(-50%, -50%);
    border       : 1px dashed #00000077;
    border-radius: 50%;
    height       : 40%;
    width        : 40%;
}

.radarLine3 {
    position     : absolute;
    left         : 50%;
    top          : 50%;
    transform    : translate(-50%, -50%);
    border       : 1px dashed #00000077;
    border-radius: 50%;
    height       : 60%;
    width        : 60%;
}

.radarLine4 {
    position     : absolute;
    left         : 50%;
    top          : 50%;
    transform    : translate(-50%, -50%);
    border       : 1px dashed #00000077;
    border-radius: 50%;
    height       : 80%;
    width        : 80%;
}

.radar .pointer {
    position                : absolute;
    z-index                 : 1024;
    left                    : 0%;
    right                   : 0%;
    top                     : 0%;
    bottom                  : 50%;
    will-change             : transform;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin   : 50% 100%;
    transform-origin        : 50% 100%;
    border-radius           : 50% 50% 0 0 / 100% 100% 0 0;
    -moz-border-radius      : 50% 50% 0 0 / 100% 100% 0 0;
    -webkit-border-radius   : 50% 50% 0 0 / 100% 100% 0 0;
    background-image        : linear-gradient(135deg, var(--primaria-darker) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
    -webkit-clip-path       : polygon(100% 0, 100% 10%, 50% 100%, 0 100%, 0 0);
    -moz-clip-path          : polygon(100% 0, 100% 10%, 50% 100%, 0 100%, 0 0);
    clip-path               : polygon(100% 0, 100% 10%, 50% 100%, 0 100%, 0 0);
    -webkit-animation       : rotate360 3s infinite linear;
    -moz-animation          : rotate360 3s infinite linear;
    animation               : rotate360 3s infinite linear;
}

.radar .pointer:after {
    content              : "";
    position             : absolute;
    width                : 50%;
    bottom               : -1px;
    border-top           : 2px solid var(--primaria);
    box-shadow           : 0 0 3px var(--primaria-dark);
    -webkit-box-shadow   : 0 0 3px var(--primaria-light);
    -moz-box-shadow      : 0 0 3px var(--primaria-darkest);
    -webkit-border-radius: 9px;
    -moz-border-radius   : 9px;
    -ms-border-radius    : 9px;
    -o-border-radius     : 9px;
    border-radius        : 9px;
}

.radarOff {
    cursor: pointer;
}

.dot-1,
.dot-2,
.dot-3,
.dot-4,
.dot-5,
.dot-zero {
    position                 : absolute;
    width                    : 25px;
    height                   : 25px;
    background               : #A5BDD3;
    display                  : block;
    -webkit-border-radius    : 100%;
    -moz-border-radius       : 100%;
    -ms-border-radius        : 100%;
    -o-border-radius         : 100%;
    border-radius            : 100%;
    animation-name           : stretch;
    animation-duration       : 3s;
    animation-timing-function: ease-out;
    animation-delay          : 0s;
    animation-iteration-count: infinite;
    animation-direction      : normal;
    animation-fill-mode      : none;
    animation-play-state     : running;
}

.dot-zero {
    background     : var(--primaria-bright);
    width          : 10px;
    height         : 10px;
    right          : 20%;
    bottom         : 50%;
    animation-delay: 2s;
}

.dot-1 {
    background     : var(--primaria-bright);
    width          : 12px;
    height         : 12px;
    right          : 70%;
    bottom         : 91px;
    animation-delay: -0.1s;
}

.dot-2 {
    background     : var(--primaria);
    width          : 26px;
    height         : 26px;
    right          : 50px;
    top            : 20%;
    animation-delay: 1.9s;
}

.dot-3 {
    background     : var(--primaria-darker);
    width          : 16px;
    height         : 16px;
    right          : 60px;
    bottom         : 31px;
    animation-delay: 1s;
}

.dot-4 {
    background     : var(--primaria-brighter);
    width          : 42px;
    height         : 42px;
    left           : 30px;
    bottom         : 120px;
    animation-delay: 2.5s;
}

.dot-5 {
    background     : var(--primaria-dark);
    width          : 32px;
    height         : 32px;
    right          : 30px;
    bottom         : 60px;
    animation-delay: 1.2s;
}

.red {
    width: 100%;
    color: var(--bs-danger);
}

.green {
    width: 100%;
    color: var(--bs-sucess);
}

@-moz-keyframes rotate360 {
    0% {
        -moz-transform: rotate(0deg);
        transform     : rotate(0deg);
    }

    to {
        -moz-transform: rotate(-360deg);
        transform     : rotate(-360deg);
    }
}

@-webkit-keyframes rotate360 {
    0% {
        -webkit-transform: rotate(0deg);
        transform        : rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-360deg);
        transform        : rotate(-360deg);
    }
}

@keyframes rotate360 {
    0% {
        -webkit-transform: rotate(0deg);
        transform        : rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-360deg);
        transform        : rotate(-360deg);
    }
}

@-moz-keyframes stretch {

    10%,
    100% {
        -moz-transform: scale(1);
    }

    0% {
        -moz-transform: scale(1);

    }

    5% {
        -moz-transform: scale(1.3);

    }
}

@-webkit-keyframes stretch {

    10%,
    100% {
        -webkit-transform: scale(1);
    }

    0% {
        -webkit-transform: scale(1);

    }

    5% {
        -webkit-transform: scale(1.3);

    }
}

@keyframes stretch {

    10%,
    100% {
        transform: scale(1);
    }

    0% {
        transform: scale(1);

    }

    5% {
        transform: scale(1.3);

    }
}