.fullCenter {
    width: 100%;
}

.imagemPainel {
    max-width : 100%;
    max-height: 100%;
}

.container {
    margin: 0 10% 10% 10%;
}

.blocoImagem {
    box-sizing     : border-box;
    max-width      : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.toBottom {
    position       : absolute;
    bottom         : 0;
    width          : 100%;
    justify-content: flex-end;
    vertical-align : bottom;
    display        : flex;
    margin-bottom  : 1em;
    flex-direction : column;
}

.card {
    background-color: var(--grey-600);
    border-radius   : 0.5rem;
    padding         : 2em;
}

.card-success {
    background-color: #90cf90;
    font-weight     : 600;
    color           : #213547;
    border-radius   : 0.5rem;
    padding         : 2em;
    margin-top      : 1em;
}

.card-error {
    background-color: #1a0606;
    border-radius   : 0.5rem;
    padding         : 2em;
    margin-top      : 1em;
}

.corpo {
    position       : relative;
    display        : flex;
    margin         : 0px auto;
    width          : calc(100% - 2rem);
    flex-direction : column;
    height         : 100vh;
    max-height     : 100vh;
    box-sizing     : border-box;
    justify-content: space-between;
}

.full {
    position  : relative;
    height    : 100vh;
    box-sizing: border-box;
    padding   : 15px;
}

.flexBlock {
    height : 100%;
    padding: 0.5rem 0;
}

.canvasAssinatura {
    width           : 100%;
    height          : 100%;
    min-height      : 40vh;
    max-height      : 100%;
    background-color: #00000033;
    border          : 2px solid var(--primaria);
    border-radius   : 0.5rem;
}

.telaFill {
    display       : flex;
    flex-direction: column;
    height        : 100vh;
    padding       : 1rem;
}

.text-disabled {
    color: #888 !important;
}

.controle {
    display: flex;
    width  : 100%;
}

.telaAjustada {
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    padding-bottom : calc(2rem + 5vh);
}

.iconLocation {
    display    : flex;
    align-items: center;
    margin     : 0;
    padding    : 0;
}

.rodapeFloat {
    background-color: #fff;
    position        : sticky;
    bottom          : 0;
    border-radius   : 2.5rem 2.5rem 0 0;
    width           : 100vw;
    z-index         : 1000;
    display         : flex;
    flex-direction  : column;
    bottom          : 0;
    padding         : 1.5rem;
    justify-content : space-around;
    align-items     : center;
}

.greyDisk {
    background-color: #ccc;
    height          : 3rem;
    width           : 3rem;
    font-size       : 1.8rem;
    border-radius   : 50%;
    display         : flex;
    justify-content : center;
    align-items     : center;
}

.fs01 {
    font-size: 0.75rem;
}

.status-grid:only-child,
.status-grid:only-child .grid-item {
    grid-column: span 3;
}

.status-grid:nth-child(2) .grid-item {
    grid-column: span 2;
}

.status-grid:nth-child(3) .grid-item {
    grid-column: span 1;
}