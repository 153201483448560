.parent {
    position  : relative;
    height    : 120px;
    width     : 240px;
    margin-top: 30%;
    transform : translate(0, -50%);
}

.gear1 {
    position                 : absolute;
    top                      : 30%;
    left                     : 15.5%;
    width                    : 80px;
    height                   : 80px;
    margin                   : auto;
    background               : var(--primaria);
    border-radius            : 50%;
    animation-name           : spin;
    animation-duration       : 5s;
    animation-direction      : reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.gear1,
.gear1 .tooth {
    background-color: var(--primaria-bright);
}

.gear2 {
    position                 : absolute;
    top                      : -11%;
    left                     : 46%;
    width                    : 80px;
    height                   : 80px;
    margin                   : auto;
    background               : var(--primaria);
    border-radius            : 50%;
    animation-name           : spin;
    animation-duration       : 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.gear2,
.gear2 .tooth {
    background-color: var(--primaria-brighter);
}

.gear3 {
    position                 : absolute;
    top                      : 71%;
    left                     : 46%;
    width                    : 80px;
    height                   : 80px;
    margin                   : auto;
    background               : var(--primaria);
    border-radius            : 50%;
    animation-name           : spin;
    animation-duration       : 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.gear1 .center,
.gear2 .center,
.gear3 .center {
    position     : absolute;
    top          : 40px;
    left         : 40px;
    z-index      : 10;
    width        : 40px;
    height       : 40px;
    transform    : translate(-50%, -50%);
    background   : #fff;
    border-radius: 50%;
}

.tooth {
    position     : absolute;
    top          : -6px;
    left         : 29.6px;
    z-index      : 1;
    width        : 20px;
    height       : 92px;
    border-radius: 20%;
    background   : var(--primaria);
}

.tooth:nth-child(2) {
    transform: rotate(45deg);
}

.tooth:nth-child(3) {
    transform: rotate(90deg);
}

.tooth:nth-child(4) {
    transform: rotate(135deg);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}