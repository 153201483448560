.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color           : var(--white) !important;
    background-color: var(--primaria) !important;
}

.nav-link {
    color: var(--primaria) !important;
}

.offcanvas {
    width: 75% !important;
}

.btn-close {
    box-shadow      : 0px 2px 8px 2px rgb(0 0 0 / 20%);
    padding         : 1rem !important;
    border-radius   : 50% !important;
    background-color: white !important;
    opacity         : 1;
}


.nav.nav-pills {
    display   : flex;
    flex-wrap : nowrap;
    overflow-x: auto;
    gap       : 0.5rem;
}

.nav-item {
    background-color: #cccccc77;
    border-radius   : 0.5rem;
}

.accordion-button {
    background-color: var(--primaria);
    color           : white;
}

.accordion-button::after {
    filter: invert(1)
}

.accordion-button:not(.collapsed) {
    color           : var(--primaria);
    background-color: var(--primaria-brightest);
}

.accordion-button:not(.collapsed)::after {
    filter: invert(0) brightness(0);
}

.accordion-button:focus {
    border-color: var(--primaria-bright);
    box-shadow  : 0 0 0px 5px var(--primaria-bright);
}

.leaflet-popup {
    max-height    : 300px;
    padding-bottom: 3rem;
}

.marker-cluster-customa {
    background     : var(--primaria);
    border         : 3px solid white;
    border-radius  : 50%;
    color          : #ededed;
    font-size      : 1.25rem;
    font-weight    : 800;
    line-height    : 37px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}